// src/store.js

// Importing configureStore from Redux Toolkit
import { configureStore } from '@reduxjs/toolkit';
// import userSlice from './slices/userSlice';
import searchSlice from './slices/searchSlice';
import userSlice from './slices/userSlice';
// Configuring the Redux store with user and search reducers
const store = configureStore({
    reducer: {
        search: searchSlice,
        user: userSlice,

    },
});

// Exporting the store to be used in the application
export default store;
