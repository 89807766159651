import React, { useState, useEffect, createRef } from 'react';
import { MdOutlineSearch } from 'react-icons/md';
import { FaRegClock } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { getVideoThroughTag, getVideoThroughIdVideo } from '../../apis/userApi'; // Import your API functions
import Sidebar from '../../components/sidebar/sidebar';
import { FixedSizeGrid } from 'react-window';
import Modal from 'react-modal';
import './index.css'
// Import your Sidebar component
import { FaPlay } from 'react-icons/fa';
import { formatNumber } from "../../config/userData";
// Adjust the import path as needed
import diamondIcon from '../../assets/images/diamond.png'; // Adjust the import path as needed

//REDUX
import { useSelector, useDispatch } from 'react-redux';
import { fetchSearchResults } from '../../slices/searchSlice';
import { fetchUserResults } from '../../slices/userSlice';
import hashtagImg from "../../assets/images/hashtag.png"

const MultiLayout = ({
    Img,
    profileImg,
    title,
    followers,
    following,
    title2,
    username,
    audioUrl,
    videoUrl,
    thumUrl
}) => {
    const audioRef = createRef();

    // Video play state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    // const closeModal = () => setIsModalOpen(false);
    // console.log(isModalOpen);
    return (
        <div className="song-layout-video-thumbnail">
            {Img && (
                <img
                    src={Img ? Img : "https://www.business2community.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png"}
                    alt="Video Thumbnail"
                    className="song-layout-thumbnail-image"
                    onClick={openModal}
                />
            )}
            {/* {console.log("Profile Image URL:", profileImg)} */}
            {!Img &&
                <img
                    src={profileImg ? profileImg : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                    alt="ProfileImage"
                    style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%'
                    }}
                />
            }
            {thumUrl && (
                <img
                    src={`https://dpcst9y3un003.cloudfront.net/${thumUrl}`}
                    alt="Video Thumbnail"
                    className="song-layout-thumbnail-image"
                    onClick={openModal}
                />
            )}
            <div className="song-layout-video-details" style={{ marginLeft: "25px", width: "60%" }}>
                <div className="song-layout-video-title">{title}</div>
                {username && <div className="song-layout-video-title">{username}</div>}
                <div className="song-layout-channel-info">{title2}</div>
                {followers >= 0 && (
                    <span style={{ color: "grey" }}>
                        {followers} Followers - {following} Following
                    </span>
                )}
            </div>
            {audioUrl && (
                <audio
                    ref={audioRef}
                    controlsList="download"
                    controls
                    src={`https://dpcst9y3un003.cloudfront.net/extracted_audio/${audioUrl}`}
                >
                    <source
                        src={`https://dpcst9y3un003.cloudfront.net/${audioUrl}`}
                        type="audio/aac"
                    />
                </audio>
            )}
        </div>
    );
};



const SearchComponent = () => {
    const [searchText, setSearchText] = useState('');
    // const [searchUserResults, setSearchUserResults] = useState([]);
    // const [searchHashTagsResults, setSearchHashTagsResults] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [searchHistory, setSearchHistory] = useState([]);
    const [activeFavoriteSubTab, setActiveFavoriteSubTab] = useState("Users");
    const [activeFavoriteNewSubTab, setActiveFavoriteNewSubTab] = useState("TopVideosViews");

    // const [searchVideoResults, setSearchVideoResults] = useState([]);
    // const [searchAudioResults, setSearchAudioResults] = useState([]);
    const [currentPlayingVideoIndex, setCurrentPlayingVideoIndex] = useState(null);

    //###################### HASHTAG STATES ############################//
    // const [searchHashtagResults, setSearchHashtagResults] = useState([]);
    const [modalContent, setModalContent] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [tagName, setTagName] = useState("");
    const closeModal = () => setModalIsOpen(false);
    const handleHashtagClick = async (tag_id, title) => {
        try {
            const data = await getVideoThroughTag(tag_id);
            if (data.success) {
                setModalContent(data.payload);
                setModalIsOpen(true); // Open the modal after setting the content
                setTagName(title); // Set the tag name state
            }
        } catch (error) {
            console.error('Error fetching videos for tag:', error);
        }
    };

    // Sound modal states
    const [soundModalContent, setSoundModalContent] = useState([]);
    const [soundModalIsOpen, setSoundModalIsOpen] = useState(false);
    const closeSoundModal = () => setSoundModalIsOpen(false);

    // Handle Video Through Id Click (for sounds)
    const handleVideoThroughIdClick = async (video_id, nickname) => {
        try {
            const data = await getVideoThroughIdVideo(video_id);
            if (data.message) {
                setSoundModalContent(data.payload);
                setSoundModalIsOpen(true);
                setTagName(nickname);
            }
        } catch (error) {
            console.error('Error fetching videos for sound:', error);
        }
    };


    //###################### HASHTAG STATES ############################//

    //REDUX DISPATCH
    const dispatch = useDispatch();
    const { users, hashtags, videos, audios, loading, topVideos, topCoinsVideos } = useSelector((state) => state.search);

    //###################### USER INFO BY ID HANDLE ############################//
    const { userInfo } = useSelector((state) => state.user);
    const handleUserClick = (userId) => {
        dispatch(fetchUserResults(userId));
    };
    //###################### ROW TO DISPLAY VIDEO SECTION ############################//
    const Row = ({
        columnIndex,
        rowIndex,
        style,
        video
    }) => {
        const videoIndex = rowIndex * 4 + columnIndex;
        if (!video) {
            console.log("testing", video);
            return null;
        }
        const handleThumbnailClick = () => {
            setCurrentPlayingVideoIndex(videoIndex);
        };

        const handlePause = () => {
            setCurrentPlayingVideoIndex(null);
        };

        return (
            <div style={style} className="video-card">
                {currentPlayingVideoIndex === videoIndex ? (
                    <video
                        width="100%"
                        height="360px"
                        controls
                        style={{ objectFit: "cover" }}
                        autoPlay
                        onPause={handlePause}
                    >
                        <source
                            src={`https://dpcst9y3un003.cloudfront.net/${video.video}`}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <>
                        <img
                            src={`https://dpcst9y3un003.cloudfront.net/${video.thum}`}
                            alt="Thumbnail"
                            width="100%"
                            height="360px"
                            style={{ objectFit: "cover", cursor: "pointer" }}
                            onClick={handleThumbnailClick}
                        />
                        <div
                            style={{
                                fontWeight: "bold",
                                position: 'absolute',
                                bottom: 0,
                                color: 'white',
                                padding: '0px 10px',
                                borderRadius: '5px',
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "90%"
                            }}
                        >
                            <p>
                                <FaPlay style={{ color: "white" }} /> {formatNumber(video.view)}
                            </p>
                            <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    src={diamondIcon}
                                    alt="DiamondIcon"
                                    style={{ width: "40px", height: "40px" }}
                                />
                                {formatNumber(video.diamond_value)}
                            </p>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const handleFavoriteSubTabClick = (subTab) => {
        setActiveFavoriteSubTab(subTab);
    };

    const handleFavoriteNewSubTabClick = (subTab) => {
        setActiveFavoriteNewSubTab(subTab);
    };

    // ###################### FETCHING DATA ############################//
    useEffect(() => {
        if (searchText.trim()) {
            const fetchSearchUserResults = async () => {
                // setLoading(true);
                try {
                    // const response = await searchUser(searchText);
                    // setSearchUserResults(response || []);
                    // const hashtagResponse = await searchHashTags(searchText);
                    // setSearchHashtagResults(hashtagResponse?.payload || []);
                    // const videoResponse = await searchDiscoverVideo(searchText, 1, 10);
                    // setSearchVideoResults(videoResponse?.payload || []);
                    // const audioResponse = await searchDiscoverAudio(searchText, 1, 10);
                    // setSearchAudioResults(audioResponse?.payload || []);
                    dispatch(fetchSearchResults(searchText));
                    // console.log("audioResponse", audioResponse);
                } catch (error) {
                    console.error('Error fetching search results:', error);
                    // setSearchUserResults([]); // Reset searchUserResults in case of error
                }
                // setLoading(false);
            };

            const delayDebounceFn = setTimeout(() => {
                fetchSearchUserResults();
                setSearchHistory(prev => [searchText, ...prev.filter(item => item !== searchText)]);
            }, 700); // Adding a debounce

            return () => clearTimeout(delayDebounceFn);
        } else {
            dispatch(fetchSearchResults([]));
        }
    }, [searchText, dispatch]);

    //###################### FETCHING DATA ############################//


    const recommendedSearches = [
        "Dream",
        "Music",
        "Dance",
        "Football",
        "Funny",
        "Trends",

        // "Football Match Schedule",
        // "Funny Statuses Love Life",
        // "Best Mood Music",
        // "Current Trends On Dream",
        // "Hairstyle For Men"
    ];

    return (
        <div style={{ display: "flex" }}>
            <Sidebar />
            <div className="searchComponent-main-container">
                <div className="input-wrapper">
                    <MdOutlineSearch className="login-modal-icon" />
                    <input
                        placeholder='Search'
                        type="text"
                        className='login-modal-style-input'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                {
                    loading ? (
                        <div>Loading...</div>
                    ) :
                        // users?.data?.length >= 0 || hashtags?.data?.length >= 0 ?
                        searchText.length > 0 ?
                            (
                                <div>
                                    <div className="profile-liked-btn-section">
                                        <button className={`search-liked-btn-style ${activeFavoriteSubTab === 'Users' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('Users')}>Users</button>
                                        <button className={`search-liked-btn-style ${activeFavoriteSubTab === 'TopVideos' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('TopVideos')}>Top</button>
                                        <button className={`search-liked-btn-style ${activeFavoriteSubTab === 'FavVideos' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('FavVideos')}>Videos</button>
                                        <button className={`search-liked-btn-style ${activeFavoriteSubTab === 'Sounds' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('Sounds')}>Sounds</button>
                                        <button className={`search-liked-btn-style ${activeFavoriteSubTab === 'Hashtags' ? 'active' : ''}`} onClick={() => handleFavoriteSubTabClick('Hashtags')}>Hashtags</button>
                                    </div>

                                    {activeFavoriteSubTab === "TopVideos" && (
                                        <>
                                            <div className="profile-liked-btn-section">
                                                <button className={`search-liked-btn-style ${activeFavoriteNewSubTab === 'TopVideosViews' ? 'active' : ''}`} onClick={() => handleFavoriteNewSubTabClick('TopVideosViews')}>Top Videos Views</button>
                                                <button className={`search-liked-btn-style ${activeFavoriteNewSubTab === 'TopVideosCoins' ? 'active' : ''}`} onClick={() => handleFavoriteNewSubTabClick('TopVideosCoins')}>Top Videos Coins</button>

                                            </div>
                                            {activeFavoriteNewSubTab === "TopVideosViews" && (

                                                <div style={{ overflowY: 'scroll', height: '100vh' }}>
                                                    <div className="video-cards">
                                                        <FixedSizeGrid
                                                            columnCount={4}
                                                            columnWidth={270}
                                                            height={800}
                                                            rowCount={Math.ceil((videos?.length ?? 0) / 4)}
                                                            rowHeight={365}
                                                            width={1080}
                                                        >
                                                            {({ columnIndex, rowIndex, style }) => {
                                                                const videoIndex = rowIndex * 4 + columnIndex;
                                                                const video = topVideos[videoIndex];
                                                                return (
                                                                    <Row
                                                                        key={video?.id}
                                                                        columnIndex={columnIndex}
                                                                        rowIndex={rowIndex}
                                                                        style={style}
                                                                        video={video}
                                                                    />
                                                                );
                                                            }}
                                                        </FixedSizeGrid>
                                                    </div>
                                                    {topVideos?.data?.length === 0 && (
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                                            <h2>No Content Available for the search</h2>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {activeFavoriteNewSubTab === "TopVideosCoins" && (

                                                <div style={{ overflowY: 'scroll', height: '100vh' }}>
                                                    <div className="video-cards">
                                                        <FixedSizeGrid
                                                            columnCount={4}
                                                            columnWidth={270}
                                                            height={800}
                                                            rowCount={Math.ceil((videos?.length ?? 0) / 4)}
                                                            rowHeight={365}
                                                            width={1080}
                                                        >
                                                            {({ columnIndex, rowIndex, style }) => {
                                                                const videoIndex = rowIndex * 4 + columnIndex;
                                                                const video = topCoinsVideos[videoIndex];
                                                                return (
                                                                    <Row
                                                                        key={video?.id}
                                                                        columnIndex={columnIndex}
                                                                        rowIndex={rowIndex}
                                                                        style={style}
                                                                        video={video}
                                                                    />
                                                                );
                                                            }}
                                                        </FixedSizeGrid>
                                                    </div>
                                                    {topCoinsVideos?.data?.length === 0 && (
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                                            <h2>No Content Available for the search</h2>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}



                                    {activeFavoriteSubTab === "Users" && (
                                        <div style={{ overflowY: 'scroll', height: '100vh' }}>
                                            <div>
                                                {users?.data?.map((item) => (
                                                    <div key={item.id} onClick={() => handleUserClick(item.id)}>
                                                        {console.log("userInfo", userInfo)}
                                                        <MultiLayout
                                                            // key={item.id}
                                                            profileImg={item?.profile_pic}
                                                            username={item?.nickname}
                                                            title2={`@${item?.username}`}
                                                            following={item?.Following?.length}
                                                            followers={item?.Followers?.length}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            {users?.data?.length === 0 && (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                                    <h2>No Content Available for the search</h2>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {userInfo && (
                                        <div>
                                            <h2>{userInfo.nickname}</h2>
                                            <img src={userInfo.profile_pic} alt={userInfo.nickname} />
                                            <p>{userInfo.username}</p>
                                            {/* Render other user details as needed */}
                                        </div>
                                    )}

                                    {activeFavoriteSubTab === "FavVideos" && (
                                        <div style={{ overflowY: 'scroll', height: '100vh' }}>
                                            <div className="video-cards">
                                                <FixedSizeGrid
                                                    columnCount={4}
                                                    columnWidth={270}
                                                    height={800}
                                                    rowCount={Math.ceil((videos?.length ?? 0) / 4)}
                                                    rowHeight={365}
                                                    width={1080}
                                                >
                                                    {({ columnIndex, rowIndex, style }) => {
                                                        const videoIndex = rowIndex * 4 + columnIndex;
                                                        const video = videos[videoIndex];
                                                        return (
                                                            <Row
                                                                key={video?.id}
                                                                columnIndex={columnIndex}
                                                                rowIndex={rowIndex}
                                                                style={style}
                                                                video={video}
                                                            />
                                                        );
                                                    }}
                                                </FixedSizeGrid>
                                            </div>
                                            {videos?.length === 0 && (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                                    <h2>No Content Available for the search</h2>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {activeFavoriteSubTab === "Sounds" && (
                                        <div>
                                            <div style={{ overflowY: 'scroll', height: '100vh' }}>
                                                {audios?.map(item => {
                                                    if (!item?.[0]) return null;
                                                    const audioItem = item[0];
                                                    return (
                                                        <div key={audioItem?.id} onClick={() => handleVideoThroughIdClick(audioItem?.video_id, audioItem?.user?.nickname)}>
                                                            <MultiLayout
                                                                Img={audioItem?.user?.profile_pic}
                                                                username={audioItem?.user?.nickname}
                                                                title2={audioItem?.user?.username}
                                                                audioUrl={audioItem?.audio_url}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {audios?.data?.length === 0 && (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                                    <h2>No Content Available for the search</h2>
                                                </div>
                                            )}
                                            <Modal
                                                isOpen={soundModalIsOpen}
                                                onRequestClose={closeSoundModal}
                                                contentLabel="Videos Modal"
                                                style={{
                                                    overlay: {
                                                        zIndex: 1000,
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        transition: 'opacity 2000ms ease-in-out'
                                                    },
                                                    content: {
                                                        width: '80%',
                                                        margin: 'auto',
                                                        border: 'none'
                                                    }
                                                }}
                                            >
                                                {soundModalIsOpen && (
                                                    <div>
                                                        <div style={{ marginBottom: "40px", display: "flex", justifyContent: "space-between" }}>
                                                            <span style={{ fontSize: "20px", marginBottom: "20px" }}>
                                                                Videos with <span style={{ fontWeight: "bold" }}>#{tagName}</span> tag.
                                                            </span>
                                                            <button onClick={closeSoundModal}>Close</button>
                                                        </div>
                                                        <div className="video-cards">
                                                            <FixedSizeGrid
                                                                columnCount={4}
                                                                columnWidth={270}
                                                                height={800}
                                                                rowCount={Math.ceil(soundModalContent?.length / 4)}
                                                                rowHeight={365}
                                                                width={1080}
                                                            >
                                                                {({ columnIndex, rowIndex, style }) => {
                                                                    const videoIndex = rowIndex * 4 + columnIndex;
                                                                    const video = soundModalContent[videoIndex];
                                                                    return <Row columnIndex={columnIndex} rowIndex={rowIndex} style={style} video={video} />;
                                                                }}
                                                            </FixedSizeGrid>
                                                        </div>
                                                    </div>
                                                )}
                                            </Modal>
                                        </div>
                                    )}

                                    {activeFavoriteSubTab === "Hashtags" && (
                                        <div style={{ overflowY: 'scroll', height: '100vh' }}>
                                            {hashtags.map(item => (
                                                <div
                                                    key={item.id}
                                                    className="song-layout-video-thumbnail"
                                                    onClick={() => handleHashtagClick(item.tag_id, item.title)}
                                                >
                                                    <div style={{
                                                        backgroundColor: 'rgba(53, 34, 195, 1)',
                                                        marginRight: '15px',
                                                        borderRadius: '5px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '50px',
                                                        height: '50px'
                                                    }}>
                                                        <img
                                                            src={hashtagImg} // Adjust if needed
                                                            alt="Hashtag Thumbnail"
                                                            className="song-layout-thumbnail-image"
                                                            style={{ height: '30px', width: '30px' }}
                                                        />
                                                    </div>
                                                    <div className="song-layout-video-details">
                                                        <div className="song-layout-video-title">
                                                            {item.title}
                                                        </div>
                                                        <div className="song-layout-channel-info">
                                                            <span>
                                                                Number of videos:
                                                                <span style={{ fontWeight: 'bolder', color: 'black' }}>
                                                                    {item.num_of_video}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="song-layout-options-menu">
                                                        ...
                                                    </div>
                                                </div>
                                            ))}
                                            {hashtags.length === 0 && (
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '50vh'
                                                }}>
                                                    <h2>No Content Available for the search</h2>
                                                </div>
                                            )}

                                        </div>
                                    )}

                                    <Modal
                                        isOpen={modalIsOpen}
                                        onRequestClose={() => setModalIsOpen(false)}
                                        contentLabel="Videos Modal"
                                        style={{
                                            overlay: {
                                                zIndex: 1000,
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                transition: 'opacity 2000ms ease-in-out'
                                            },
                                            content: {
                                                width: '80%',
                                                margin: 'auto',
                                                border: 'none'
                                            }
                                        }}
                                    >
                                        {modalIsOpen && (
                                            <div>
                                                <div style={{ marginBottom: "40px", display: "flex", justifyContent: "space-between" }}>
                                                    <span style={{ fontSize: "20px", marginBottom: "20px" }}>
                                                        Videos with <span style={{ fontWeight: "bold" }}>#{tagName}</span> tag.
                                                    </span>
                                                    <button onClick={closeModal}>Close</button>
                                                </div>
                                                {/* <div className="fav-video-cards">
                                            {modalContent.map((video, index) => (
                                                <VideoCard key={video.id} video={video.videoAssociation} videoIndex={index} />
                                            ))}
                                        </div> */}
                                                <div className="video-cards">
                                                    <FixedSizeGrid
                                                        columnCount={4} // Number of columns in the grid
                                                        columnWidth={270} // Width of each column
                                                        height={800} // Height of the grid
                                                        rowCount={Math.ceil(modalContent?.length / 4)} // Number of rows
                                                        rowHeight={365} // Height of each row
                                                        width={1080} // Fixed width for the grid (must be a number)
                                                    >
                                                        {({ columnIndex, rowIndex, style }) => {
                                                            const videoIndex = rowIndex * 4 + columnIndex;
                                                            const video = modalContent[videoIndex]?.videoAssociation;
                                                            return <Row columnIndex={columnIndex} rowIndex={rowIndex} style={style} video={video} />;
                                                        }}
                                                    </FixedSizeGrid>
                                                </div>
                                                {/* {console.log("modalContent", modalContent)} */}
                                            </div>
                                        )}

                                    </Modal>

                                </div>
                            ) : (
                                <div>
                                    <div>
                                        {searchHistory.map((historyItem, index) => (
                                            <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 20px 10px 20px", cursor: "pointer" }} >
                                                <div onClick={() => setSearchText(`${historyItem}`)}>
                                                    <FaRegClock />
                                                    <span style={{ fontWeight: "bold", margin: "0 0 0 10px" }}>{historyItem}</span>
                                                </div>
                                                <ImCross onClick={() => setSearchHistory(prev => prev.filter(item => item !== historyItem))} />
                                            </div>
                                        ))}
                                        <div style={{ fontWeight: "bold", margin: "0 0 0 10px" }}>Recommended Search</div>
                                        <div>
                                            {recommendedSearches.map((item, index) => (
                                                <div key={index} style={{ margin: "10px 20px 10px 20px", cursor: "pointer" }} onClick={() => setSearchText(`${item}`)}>
                                                    <span>🔴</span>
                                                    <span style={{ margin: "0 0 0 10px", fontWeight: "500" }}>{item}</span>
                                                    <span style={{ margin: "0 0 0 10px" }}>🔥</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )
                }
            </div>
        </div>
    );
};

export default SearchComponent;



