import axios from "axios";
const SERVER_API_URL = "https://api.dreamlived.com";
const token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNrQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoic2h1YmhhbXMiLCJpYXQiOjE3MjA0MzkwMzl9.iWvCrA_B96wlv3uIkLFuBsjRFjAQJJ1XvbQ5VUw_xMI';

const follow = async (data, token) => {
    const config = {
        headers: { Authorization: token }
    };
    const url = `${SERVER_API_URL}/users/follow`;
    const result = await axios.post(url, data, config)
    return result.data;
}

const unfollow = async (data, token) => {
    const config = {
        headers: { Authorization: token }
    };
    const url = `${SERVER_API_URL}/users/unfollow`;
    const result = await axios.post(url, data, config)
    return result.data;
}

const updateProfile = async (token, data) => {
    const config = {
        headers: { Authorization: token }
    };
    const url = `${SERVER_API_URL}/users/update`;
    const result = await axios.patch(url, data, config);
    return result.data;
};

const checkUsernameAvailable = async (value) => {
    const url = `${SERVER_API_URL}/users/checkUsernameAvaliable/${value}`;
    try {
        const result = await axios.get(url);
        return result.data.available;
    } catch (error) {
        console.error("Error checking username availability:", error);
        throw error;
    }
};

const getfavouritehashtag = async () => {
    const config = {
        headers: { Authorization: token }
    };
    const url = `${SERVER_API_URL}//favouritehashtag/getfavouritehashtag`;
    try {
        const result = await axios.get(url, config);
        return result.data;
    } catch (error) {
        console.error("Error checking hastags availability:", error);
        throw error;
    }
};



const getFavouriteSound = async () => {
    const config = {
        headers: { Authorization: token }
    };
    const url = `${SERVER_API_URL}//sound/getFavouriteSound`;
    try {
        const result = await axios.get(url, config);
        return result.data;
    } catch (error) {
        console.error("Error checking sound availability:", error);
        throw error;
    }
};

const getFavouriteVideo = async () => {
    const config = {
        headers: { Authorization: token }
    };
    const url = `${SERVER_API_URL}//videos/getFavouriteVideo`;
    try {
        const result = await axios.get(url, config);
        return result.data;
    } catch (error) {
        console.error("Error checking videos availability:", error);
        throw error;
    }
};


const getUserFavouriteUsers = async () => {
    const config = {
        headers: { Authorization: token }
    };
    const url = `${SERVER_API_URL}//users/getUserFavouriteUsers`;
    try {
        const result = await axios.get(url, config);
        return result.data;
    } catch (error) {
        console.error("Error checking users availability:", error);
        throw error;
    }
};

const getUserById = async userId => {
    const url = `${SERVER_API_URL}//users/infoById/${userId}`;
    try {
        const result = await axios.get(url);
        console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking infoById availability:", error);
        throw error;
    }
};


const getVideoThroughTag = async tag_id => {
    const url = `${SERVER_API_URL}//search/getVideoThroughTag/${tag_id}`;
    try {
        const result = await axios.get(url);
        // console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking username availability:", error);
        throw error;
    }
};

const getVideoThroughIdVideo = async video_id => {
    const url = `${SERVER_API_URL}//videos/getVideoByVideoId/${video_id}`;
    try {
        const result = await axios.get(url);
        console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking username availability:", error);
        throw error;
    }
};


const searchUser = async text => {
    const url = `${SERVER_API_URL}//search/searchUser/${text}/`;
    try {
        const result = await axios.get(url);
        // console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking searchUser availability:", error);
        throw error;
    }
};

const searchHashTags = async text => {
    const url = `${SERVER_API_URL}//search/gethastag/${text}/`;
    try {
        const result = await axios.get(url);
        // console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking searchUser availability:", error);
        throw error;
    }
};
const searchDiscoverVideo = async (text, page, pageSize) => {
    const config = {
        headers: {
            Authorization: token
        }
    };
    const url = `${SERVER_API_URL}//search/discoverVideo/${text}/${page}/${pageSize}`;
    try {
        const result = await axios.get(url, config);
        // console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking discoverVideo availability:", error);
        throw error;
    }
};

const searchDiscoverAudio = async (text, page, pageSize) => {
    const config = {
        headers: {
            Authorization: token
        }
    };
    const url = `${SERVER_API_URL}//audio/getdiscoverbysound/${text}/${page}/${pageSize}`;
    try {
        const result = await axios.get(url, config);
        // console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking audio availability:", error);
        throw error;
    }
};

const discoverVideobyview = async (text, page, pageSize) => {
    const config = {
        headers: {
            Authorization: token
        }
    };
    const url = `${SERVER_API_URL}/search/discoverVideobyview/${text}/${page}/${pageSize}`;
    try {
        const result = await axios.get(url, config);
        // console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking discoverVideobyview availability:", error);
        throw error;
    }
};

const discoverVideobycoins = async (text, page, pageSize) => {
    const config = {
        headers: {
            Authorization: token
        }
    };
    const url = `${SERVER_API_URL}/search/discoverVideobycoins/${text}/${page}/${pageSize}`;
    try {
        const result = await axios.get(url, config);
        // console.log("API Response:", result.data); // Add this line to log the response
        return result.data;
    } catch (error) {
        console.error("Error checking discoverVideobycoins availability:", error);
        throw error;
    }
};

export {
    follow,
    unfollow,
    updateProfile,
    checkUsernameAvailable,
    getfavouritehashtag,
    getFavouriteSound,
    getFavouriteVideo,
    getUserFavouriteUsers,
    getVideoThroughTag,
    searchUser,
    searchHashTags,
    searchDiscoverVideo,
    searchDiscoverAudio,
    discoverVideobyview,
    discoverVideobycoins,
    getVideoThroughIdVideo,
    getUserById
};
