import React, { useState } from 'react';
import Modal from "react-modal";

import { signIn, signUp } from '../../../server/auth.api';

import { MdOutlineEmail, MdLockOutline } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaRegUser } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import {
    signInWithPopup,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    createUserWithEmailAndPassword
} from "firebase/auth";
import { auth } from '../../../firebaseConfig';
import axios from 'axios';

import './index.css'

const AuthPage = () => {
    // const [userDetails, setUserDetails] = useState(null);
    const [login, setLogin] = useState(true);
    const [registration, setRegistration] = useState(false);
    const [loginWithEmail, setLoginWithEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();

    // console.log("userDetails", userDetails)

    // useEffect(() => {
    //     const storedUserDetails = localStorage.getItem('userDetails');
    //     if (storedUserDetails) {
    //         setUserDetails(JSON.parse(storedUserDetails));
    //     }
    // }, []);

    const handleClose = () => {
        navigate('/', { replace: true });
    };

    const handleState = (state) => {
        setLogin(state === 'login');
        setRegistration(state === 'registration');
        setLoginWithEmail(state === 'loginWithEmail');
        setErrors({});
        setEmail('');
        setPassword('');
        setUsername('');
    };

    const handleRedirection = (user) => {
        if (user.accessToken) {
            navigate('/', { replace: true });
        } else {
            alert('Please verify your email before logging in.');
        }
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };



    const validateRegistration = () => {
        const newErrors = {};
        if (!username) newErrors.username = 'Username is required';
        if (!email) newErrors.email = 'Email is required';
        else if (!validateEmail(email)) newErrors.email = 'Email address is invalid';
        if (!password) newErrors.password = 'Password is required';
        else if (password.length < 6) newErrors.password = 'Password must be at least 6 characters';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const loginWithEmailPassword = (event) => {
        event.preventDefault();
        setTimeout(async () => {
            if (email && password) {
                try {

                    signInWithEmailAndPassword(auth, email, password)
                        .then((res) => {
                            handleRedirection(res.user);
                            const result = signIn(res.user.email)
                            console.log(result, "result")
                            result.then((res) => {
                                localStorage.setItem('userDetails', JSON.stringify(res.data.payload));
                            })
                                .catch((err) => {
                                    console.log("server error:", err)
                                })
                        })
                        .catch((err) => {
                            console.log("error generated while login from firebase:", err.message)
                        })
                } catch (error) {
                    alert(error.message);
                }
            } else {
                console.log("effor")
            }
        }, 2000);
    };

    const loginWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                const { displayName, email, uid, photoURL } = result.user;
                const userExists = await signIn(email);
                console.log("userExists", userExists)
                if (userExists.data.message === 'user not found') {
                    const result = await signUp(displayName, email, uid, photoURL);
                    if (result.data.message === 'user created successfully') {
                        localStorage.setItem('userDetails', JSON.stringify(result.data.payload));
                        handleRedirection(result.user);
                    }
                } else {
                    localStorage.setItem('userDetails', JSON.stringify(userExists.data.payload));
                    handleRedirection(result.user);
                }
            })
            .catch((err) => console.log(err.message));
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        if (validateRegistration()) {
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    const name = username;
                    const firebase_uid = user.uid;
                    const profile_pic = ''; // Add profile picture if available
                    const data = {
                        name,
                        email,
                        firebase_uid,
                        profile_pic
                    };
                    axios.post("https://api.dreamlived.com/users/signup", data)
                        .then(response => {
                            // console.log("User registered and data saved:", response.data);
                            handleState('login')
                        })
                        .catch(error => {
                            console.error("Error saving user data:", error);
                            // Handle error saving user data
                        });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.error("Registration error:", errorCode, errorMessage);
                    // Handle specific errors or display to user
                });
        }
    };

    const customStyles = {
        content: {
            width: "30%",
            height: "80%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            transition: "opacity 2000ms ease-in-out",
        },
    };

    return (
        <div className="auth-page">
            <Modal isOpen={true} onRequestClose={handleClose} style={customStyles}>
                {login && (
                    <div className="login-modal-style">
                        <h1 style={{ marginBottom: "-25px" }}>Dream</h1>
                        <h2 style={{ marginBottom: "-5px", color: "grey", fontWeight: "500" }}>Log in with email and password.</h2>
                        <div className="input-wrapper">
                            <MdOutlineEmail className="login-modal-icon" />
                            <input
                                placeholder='Email'
                                type="email"
                                className='login-modal-style-input'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="input-wrapper">
                            <MdLockOutline className="login-modal-icon" />
                            <input
                                placeholder='Password'
                                type="password"
                                className='login-modal-style-input'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <span className="error">{errors.password}</span>}
                        </div>
                        <button
                            className='login-modal-style-btn'
                            style={{ backgroundColor: "rgb(254, 44, 85)", color: "white", border: "none" }}
                            onClick={loginWithEmailPassword}
                        >
                            Log in
                        </button>
                        <div className='login-modal-style-btn'>Forget password</div>

                        <span className='signUp-modal-terms'>
                            By continuing, you agree to Dream’s Terms of Service and confirm that you have read Dream’s Privacy Policy.
                        </span>

                        <div className="login-modal-signup" style={{ position: "absolute", bottom: "40px" }}>
                            Don't have an account?
                            <span style={{ cursor: "pointer", color: "rgb(254, 44, 85)" }} onClick={() => handleState('registration')}> Sign Up</span>
                        </div>
                    </div>
                )}
                {registration && (
                    <div className="signUp-modal-style">
                        <h1 style={{ marginBottom: "-25px" }}>Dream</h1>
                        <h2 style={{ marginBottom: "-5px", color: "grey", fontWeight: "500" }}>Sign Up with email and password.</h2>
                        <div className='login-modal-style-btn' onClick={() => handleState('loginWithEmail')}><FaRegUser style={{ position: "absolute", left: "10px", fontSize: "20px" }} />
                            Use phone or email</div>
                        <div className='login-modal-style-btn'><FaFacebook style={{ position: "absolute", left: "10px", color: "#1877f2", fontSize: "20px" }} />
                            Continue with Facebook</div>
                        <div className='login-modal-style-btn' onClick={loginWithGoogle}><FcGoogle style={{ position: "absolute", left: "10px", fontSize: "20px" }} />
                            Continue with Google</div>
                        <span className='signUp-modal-terms'>
                            By continuing, you agree to Dream’s Terms of Service and confirm that you have read Dream’s Privacy Policy.
                        </span>
                        <div style={{ position: "absolute", bottom: "40px" }}>
                            Already have an account?
                            <span style={{ cursor: "pointer", color: "rgb(254, 44, 85)" }} onClick={() => handleState('login')}> Log In</span>
                        </div>
                    </div>
                )}
                {loginWithEmail && (
                    <div className="login-modal-style">
                        <h1 style={{ marginBottom: "-25px" }}>Dream</h1>
                        <h2 style={{ marginBottom: "-5px", color: "grey", fontWeight: "500" }}>Sign up with email and password.</h2>
                        <div className="input-wrapper">
                            <FaRegUser className="login-modal-icon" />
                            <input
                                placeholder='User Name'
                                type="text"
                                className='login-modal-style-input'
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            {errors.username && <span className="error">{errors.username}</span>}
                        </div>
                        <div className="input-wrapper">
                            <MdOutlineEmail className="login-modal-icon" />
                            <input
                                placeholder='Email'
                                type="email"
                                className='login-modal-style-input'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="input-wrapper">
                            <MdLockOutline className="login-modal-icon" />
                            <input
                                placeholder='Password'
                                type="password"
                                className='login-modal-style-input'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <span className="error">{errors.password}</span>}
                        </div>
                        <button
                            className='login-modal-style-btn'
                            style={{ backgroundColor: "rgb(254, 44, 85)", color: "white", border: "none" }}
                            onClick={handleRegistration}
                        >
                            Sign Up
                        </button>

                        <span className='signUp-modal-terms'>
                            By continuing, you agree to Dream’s Terms of Service and confirm that you have read Dream’s Privacy Policy.
                        </span>

                        <div style={{ position: "absolute", bottom: "40px" }}>
                            Already have an account?
                            <span style={{ cursor: "pointer", color: "rgb(254, 44, 85)" }} onClick={() => handleState('login')}> Log In</span>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default AuthPage;
