import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserById } from "../apis/userApi";

// Defining an async thunk to fetch search results
export const fetchUserResults = createAsyncThunk(
    'user/fetchUserResults',
    async (userId, { rejectWithValue }) => {
        try {
            const userInfoResponse = await getUserById(userId);
            // Returning the fetched data
            return {
                userInfo: userInfoResponse || []
            };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Creating a slice for managing search-related state
const userSlice = createSlice({
    name: 'user', // Name of the slice
    initialState: {
        userInfo: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Handling different states of the fetchSearchResults async thunk
        builder
            .addCase(fetchUserResults.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserResults.fulfilled, (state, action) => {
                state.userInfo = action.payload.userInfo;
                state.loading = false;
            })
            .addCase(fetchUserResults.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch user id results';
            });
    },
});

// Exporting actions and the reducer
export default userSlice.reducer;