import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Profile from "./pages/profile";
import Messages from "./pages/messages";
import AuthPage from './pages/Authentication/authPage';
import SingleVideo from './pages/SingleVideo'; 
import SearchComponent from "./pages/searchPage";


const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/" element={<Home />} />
      <Route path="/video/:video_id" element={<SingleVideo />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/search" element={<SearchComponent />} />
    </Routes>
  </BrowserRouter>
);

export default App;
