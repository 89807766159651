import React, { useRef } from 'react';
import './buttonElm.css'; // Assuming you have a CSS file named Button.css

function ButtonElm() {
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        // Handle the file selection and upload process
        if (files.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }

            // Example: You can use fetch or axios to upload the files to your server
            fetch('/upload', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Files uploaded successfully:', data);
                })
                .catch(error => {
                    console.error('Error uploading files:', error);
                });
        }
    };

    return (
        <div>
            <button className="postButton" onClick={handleButtonClick}>
                Upload
            </button>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                multiple
                accept="image/*,video/*"
            />
        </div>
    );
}

export default ButtonElm;
